import * as React from 'react';

import { AnalyticsPageViewProvider } from '~/libraries/analytics/provider.client';

//
//

export const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <AnalyticsPageViewProvider />
      {children}
    </>
  );
};
