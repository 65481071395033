import { TFunction } from 'i18next';
import { MessageParams, setLocale } from 'yup';

//
//

export const updateYupTranslations = (t: TFunction) =>
  setLocale({
    mixed: {
      default: translate(t, 'validation:invalid'),
      required: translate(t, 'validation:required'),
      notType: translate(t, 'validation:required'),
    },
    string: {
      length: translate(t, 'validation:string.length'),
      min: translate(t, 'validation:string.min'),
      max: translate(t, 'validation:string.max'),
      email: translate(t, 'validation:string.email'),
    },
    number: {
      min: translate(t, 'validation:number.min'),
      max: translate(t, 'validation:number.max'),
    },
    date: {
      min: translate(t, 'validation:date.min'),
      max: translate(t, 'validation:date.max'),
    },
  });

//
//

const translate =
  (t: TFunction, id: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ label, path, ...params }: MessageParams & any) => {
    return t(id, {
      label: label || path,
      count: params.length || params.min || params.max,
      ...params,
    });
  };
